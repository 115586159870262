.rdtOpen .rdtPicker {
  display: block;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.rdtPicker {
  position: absolute;
  width: 250px !important;
  padding: 4px;
  margin-top: 1px;
  z-index: 99;
  background: #f8f9fa !important;
  /* width: 100%; */
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 15px;
  line-height: 19px;
  width: 15px;
  padding: 0.3rem;
  border-radius: 0.2rem;
}
